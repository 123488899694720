<template>
  <div class="home">
    <a-breadcrumb>
      <a-breadcrumb-item href="">
        <a-icon type="home" />
      </a-breadcrumb-item>
      <a-breadcrumb-item href="">
        <a-icon type="user" />
        <span>监控管理</span>
      </a-breadcrumb-item>
      <a-breadcrumb-item> 设备管理 </a-breadcrumb-item>
    </a-breadcrumb>

    <div class="search-list-box">
      <a-form
        class="ant-advanced-search-form"
        :form="form"
        @submit="handleSearch"
      >
        <a-row :gutter="24">
          <a-col :span="6">
            <a-form-item :label="`设备编号：`">
              <a-input placeholder="请输入设备编号" />
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <a-form-item :label="`选择日期：`">
               <a-range-picker
                :show-time="{
                  hideDisabledOptions: true,
                  defaultValue: [moment('00:00:00', 'HH:mm:ss'), moment('11:59:59', 'HH:mm:ss')],
                }"
                style="width: 120px"
                format="YYYY-MM-DD"
              />
            </a-form-item>
          </a-col>
          
          <a-col
            :span="8"
            :style="{
              textAlign: 'left',
              paddingLeft: '25px',
              marginTop: '6px',
            }"
          >
          <a-button type="primary" html-type="submit"> 导出 </a-button>
            <a-button type="primary" html-type="submit" class="marginr20"> 查询 </a-button>
            <a-button :style="{ marginLeft: '8px' }" @click="handleReset">
              重置
            </a-button>
          </a-col>
        </a-row>
      </a-form>
      <div class="search-result-list">
        <div class="table-header-box">
          <div class="left">
            <h3 class="title">报表列表</h3>
          </div>
          <div class="right">
            
          </div>
        </div>
        <a-table
          :row-selection="rowSelection"
          :columns="columns"
          :data-source="data"
          :scroll="{ x: 1300, y: 300 }"
          :show-total="(total) => `Total ${total} items`"
          size="small"
        >
         
        </a-table>
      </div>
    </div>

  
  </div>
</template>

<script>
import moment from 'moment';
const rowSelection = {
  onChange: (selectedRowKeys, selectedRows) => {
    console.log(
      `selectedRowKeys: ${selectedRowKeys}`,
      "selectedRows: ",
      selectedRows
    );
  },
  onSelect: (record, selected, selectedRows) => {
    console.log(record, selected, selectedRows);
  },
  onSelectAll: (selected, selectedRows, changeRows) => {
    console.log(selected, selectedRows, changeRows);
  },
};
const columns1 = [
     { title: "钞箱类型", dataIndex: "address", key: "1" , sorter: (a, b) => a.age - b.age },
  { title: "面额(元)", dataIndex: "address", key: "2" , sorter: (a, b) => a.age - b.age },
  { title: "剩余数量", dataIndex: "address", key: "3" , sorter: (a, b) => a.age - b.age },
  { title: "总金额(元)", dataIndex: "address", key: "4" , sorter: (a, b) => a.age - b.age },
]
const columns = [
    { title: "设备appId", dataIndex: "address", key: "31" },
  { title: "设备编号", dataIndex: "address", key: "32" },
  { title: "机器厂家", dataIndex: "address", key: "33" },
  { title: "机器类型", dataIndex: "address", key: "34" },

   { title: "银行名称", dataIndex: "address", key: "1" },
  { title: "网点名称", dataIndex: "address", key: "2" },
  { title: "入钞总金额", dataIndex: "address", key: "3" },
  { title: "出钞总金额", dataIndex: "address", key: "4" },
  { title: "是否加钞", dataIndex: "address", key: "5" },

     
];
const data = [
  {
    key: "1",
    name: "John Brown",
    age: 32,
    address: "New York Park",
  },
  {
    key: "2",
    name: "John Brown",
    age: 32,
    address: "New York Park",
  },
  {
    key: "3",
    name: "John Brown",
    age: 32,
    address: "New York Park",
  },
  {
    key: "4",
    name: "John Brown",
    age: 32,
    address: "New York Park",
  },
  {
    key: "5",
    name: "John Brown",
    age: 32,
    address: "New York Park",
  },
  {
    key: "6",
    name: "John Brown",
    age: 32,
    address: "New York Park",
  },
  {
    key: "7",
    name: "Jim Green",
    age: 40,
    address: "London Park",
  },
];
export default {
  data() {
    return {
      expand: false,
      form: this.$form.createForm(this, { name: "advanced_search" }),
      data,
      columns,
      columns1,
      rowSelection,

      show1: false,
      show2: false,
      show3: false,
    };
  },
  computed: {
    count() {
      return this.expand ? 11 : 7;
    },
  },
  updated() {
    console.log("updated");
  },
  methods: {
      moment,
    handleSearch(e) {
      e.preventDefault();
      this.form.validateFields((error, values) => {
        console.log("error", error);
        console.log("Received values of form: ", values);
      });
    },

    handleReset() {
      this.form.resetFields();
    },

    toggle() {
      this.expand = !this.expand;
    },

    handleChange() {},

    handleSelectChange(value) {
      console.log(value);
      this.form.setFieldsValue({
        note: `Hi, ${value === 'male' ? 'man' : 'lady'}!`,
      });
    },
  },
};
</script>
<style>
.ant-advanced-search-form {
  padding: 24px;
  background: #fbfbfb;
  border: 1px solid #d9d9d9;
  border-radius: 6px;
}

.ant-advanced-search-form .ant-form-item {
  display: flex;
}

.ant-advanced-search-form .ant-form-item-control-wrapper {
  flex: 1;
}

.search-list-box .ant-form {
  max-width: none;
}
.search-list-box .search-result-list {
  margin-top: 16px;
  border: 1px dashed #e9e9e9;
  border-radius: 6px;
  background-color: #fafafa;
  min-height: 200px;
  text-align: center;
  padding-top: 80px;
}
</style>
